@mixin text-ellipsis-multi($rows: 2) {
    display: box;
    -webkit-line-clamp: $rows;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin increase-hit-map($amount: 2px) {
    &::before {
        position: absolute;
        content: '';
        top: -$amount;
        right: -$amount;
        left: -$amount;
        bottom: -$amount;
    }
}

@mixin hide-scrollbar {
    scrollbar-width: none; // sass-lint:disable-line no-misspelled-properties

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
    }
}
