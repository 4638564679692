// sass-lint:disable no-misspelled-properties

$small_content_items_margin: 24px;
$large_content_items_margin: 32px;

.buckets-container-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 16px;

    @include media-query-grid(sm) {
        grid-template-columns: repeat(3, 1fr);
        column-gap: $small_content_items_margin;
        row-gap: 64px;
        margin-top: 24px;
    }

    @include media-query-grid(md) {
        grid-template-columns: repeat(4, 1fr);
        column-gap: $large_content_items_margin;
    }
}
